import React from 'react'
import './Kontakt.css'
import mapaImg from './mapa.webp'
import LazyLoad from 'react-lazyload';
import contactImg from './contact.webp';

export default function Kontakt() {
    return (
        <div className="kontakt" id="kontakt">
            <div className="kontakt__container">
                <div className="kontakt__text">
                    <p className="kontakt__question">
                        Chcesz dowiedzieć się więcej?
                    </p>
                    <p>
                        Zapraszam do kontaktu
                    </p>
                    <LazyLoad once>
                        <img className="kontakt__adress" src={contactImg} alt="English Effect Ewa Sobel contact" />
                    </LazyLoad>
                </div>
                <div>
                    <LazyLoad once>
                        <img className="kontakt__mapa" src={mapaImg} alt="English Effect lokalizacja"></img>
                    </LazyLoad>
                </div>
            </div>
        </div>
    )
}