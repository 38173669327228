import React from 'react'
import './FormyZajec.css'
import ForumIcon from '@material-ui/icons/Forum';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import PersonIcon from '@material-ui/icons/Person';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SchoolIcon from '@material-ui/icons/School';

export default function FormyZajec() {
    return (
        <div className="formy-zajec">
            <div className="formy-zajec__main-item"> 
                <div className="formy-zajec-text">
                    <h2>Propozycje zajęć języka angielskiego</h2>

                </div>
                <div className="formy-zajec-container">
                    <div className="formy-zajec-item">
                        <div className="formy-zajec-icon">
                            <ForumIcon fontSize="large" />
                        </div>
                        <h2 className="formy-zajec__title">Konwersacje</h2>
                        <p>
                            Jeżeli znasz podstawy gramatyczne lecz brakuje Ci słownictwa z języka angielskiego lub masz wiedzę na poziomie
                            co najmniej A2/B1 ale wciąż czujesz się niepewnie w mówieniu po angielsku, te zajęcia są dla Ciebie. Słuchanie
                            i mówienie po angielsku w naturalny sposób pozwoli przełamać barierę w swobodnej komunikacji.
                        </p>
                    </div>
                    <div className="formy-zajec-item">
                        <div className="formy-zajec-icon">
                            <LocalLibraryIcon fontSize="large" />
                        </div>
                        <h2 className="formy-zajec__title">Kursy standardowe</h2>
                        <p>
                            Jeżeli zaczynasz naukę języka angielskiego, pragniesz rozwijać wszystkie aspekty języka angielskiego, lubisz
                            uczyć się poprzez kreatywne ćwiczenia i zadania utrwalające wiedzę, te zajęcia są odpowiednie dla Ciebie. Uczę
                            sprawdzonymi metodami, dzięki którym szybko zobaczysz swoje postępy w nauce.
                        </p>
                    </div>
                    <div className="formy-zajec-item">
                        <div className="formy-zajec-icon">
                            <BusinessCenterIcon fontSize="large" />
                        </div>
                        <h2 className="formy-zajec__title">Angielski w biznesie</h2>
                        <p>
                            Jeżeli angielskim posługujesz się głównie w pracy, znasz podstawy języka angielskiego lecz czujesz, że brakuje
                            Ci słownictwa i narzędzi językowych do swobodnej komunikacji z klientami lub współpracownikami, te zajęcia
                            podniosą Twoje kompetencje językowe.
                        </p>
                    </div>
                    <div className="formy-zajec-item">
                        <div className="formy-zajec-icon">
                            <SchoolIcon fontSize="large" />
                        </div>
                        <h2 className="formy-zajec__title">Przygotowanie do egzaminu maturalnego i ósmoklasisty</h2>
                        <p>
                            Jeżeli chcesz jak najlepiej wypaść na egzaminie ósmoklasisty lub egzaminie maturalnym, pomogę Ci osiągnąć ten cel.
                            Podczas zajęć poszerzymy i utrwalimy wiedzę z angielskiego, uzupełnimy ewentualne braki  nauce, będziemy ćwiczyć
                            zadania egzaminacyjne, podpowiem na co zwracać uwagę na egzaminie.
                        </p>
                    </div>
                    <div className="formy-zajec-item">
                        <div className="formy-zajec-icon">
                            <PersonIcon fontSize="large" />
                        </div>
                        <h2 className="formy-zajec__title">Zajęcia spersonalizowane</h2>
                        <p> 
                            Jeżeli znasz już język angielski na poziomie co najmniej A2/B1 i chciałbyś rozwinąć się w ściśle określonym kierunku, te zajęcia 
                            będą dostosowane tylko do Twoich potrzeb.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}