import React from 'react'
import './Olektorze.css'
import ewaBiurko from './przy-biurku.webp'
import LazyLoad from 'react-lazyload';

export default function Olektorze() {
    return (
        <div className="olektorze" id="olektorze">
            <LazyLoad once>
                <img className="olektorze-img" src={ewaBiurko} alt="Ewa Sobel English Effect" />
            </LazyLoad>
            <div className="olektorze-text">
                <p className="olektorze__title">O mnie</p>
                <p>
                    Jestem lektorem z kilkuletnim doświadczeniem w nauczaniu języka angielskiego młodzieży oraz osób dorosłych w zakresie ogólnym jak i biznesowym na wszystkich poziomach zaawansowania.
                    Zajęcia przeze mnie prowadzone są zawsze skrojone do potrzeb kursantów,
                    aby zapewnić jak najwyższy poziom satysfakcji. Lekcje prowadzę w języku angielskim, z dużym naciskiem na komunikację
                    oraz powtórki, co zwiększa skuteczność nauki. Ponieważ język angielski od zawsze był moją pasją, dbam o to, by
                    spotkania były ciekawe, inspirujące i motywujące do dalszej nauki.
                </p>
            </div>
        </div>
    )
}