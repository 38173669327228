import React from 'react'
import './Wyroznia.css'
export default function Wyroznia() {
    return (
        <div className="wyroznia">
            <div className="wyroznia__main-item">
                <div className="wyroznia__text">
                    <p className="wyroznia__text-titile">
                        Co mnie wyróżnia?
                    </p>
                    <br />
                    <div>
                        kilkuletnie doświadczenie w prowadzeniu kursów dla dorosłych i młodzieży
                        <br />
                        <br />
                        skuteczne metody nauki
                        <br />
                        <br />
                        lekcje dopasowane do celów i potrzeb  uczestników zajęć
                        <br />
                        <br />
                        zajęcia wzbogacone różnorodnymi materiałami, w tym multimedialnymi  i interaktywnymi
                        <br />
                        <br />
                        rzetelność w przygotowaniu i prowadzeniu zajęć
                        <br />
                        <br />
                        nacisk na mówienie i wzbogacanie słownictwa w języku angielskim
                    </div>
                </div>
            </div>
        </div>
    )
}