import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import './Menu.css'
import { Link } from 'react-scroll'

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="nav__menu">
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to="oferta" spy={true} smooth={true}>
          <MenuItem onClick={handleClose}>Oferta</MenuItem>
        </Link>
        <Link to="olektorze" spy={true} smooth={true}>
          <MenuItem onClick={handleClose}>O lektorze</MenuItem>
        </Link>
        <Link to="cennik" spy={true} smooth={true}>
          <MenuItem onClick={handleClose}>Cennik</MenuItem>
        </Link>
        <Link to="kontakt" spy={true} smooth={true}>
          <MenuItem onClick={handleClose}>Kontakt</MenuItem>
        </Link>
      </Menu>
    </div>
  );
}