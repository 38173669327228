import React, { useState, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import './Test.css'
import LazyLoad from 'react-lazyload';
import logoImg from '../Nav/Logo/logo.webp'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function Test(props) {
    const [formData, setFormData] = useState(JSON.parse(localStorage.getItem(props.match.params.id)));
    const [restError, setRestError] = useState(false);

    useEffect(() => storeNewData("userId", props.match.params.id), []);

    function handleOnChange(e) {
        console.log(props);
        const { value, name } = e.target
        storeNewData(name, value);
    }

    function storeNewData(name, value) {
        const newData = { ...formData, [name]: value };
        setFormData(newData);
        localStorage.setItem(props.match.params.id, JSON.stringify(newData));
    }

    function getFormControl(taskNb, question, itemList) {
        return (
            <React.Fragment>
                <div className="test__question">
                    <FormControl component="fieldset" size='small'>
                        <FormLabel component="legend">
                            {taskNb + ". " + question}
                        </FormLabel>
                        <RadioGroup aria-label="gender" name={taskNb} onChange={handleOnChange}>
                            {itemList.map((e, index) => <FormControlLabel key={index} value={e} checked={formData?.[taskNb] === e} label={e} control={<Radio />} />)}
                        </RadioGroup>
                    </FormControl>
                </div>

            </React.Fragment>
        )
    }

    function handleSubmit(event) {
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: JSON.stringify(formData)
        }

        fetch("/service-provider/english-effect/evaluateTest", requestOptions)
            .then((r) => {
                if (r.status !== 200) {
                    setRestError(true)
                } else {
                    storeNewData("submitted", true);
                }
            });
    }

    function renderErrorMsg() {
        return (
            <Snackbar autoHideDuration={6000} open={true} onClose={() => setRestError(false)}>
                <Alert onClose={() => setRestError(false)} severity="error">
                    Nie można wysłać testu, spróbuj później lub skontaktuj się z lektorem.
                </Alert>
            </Snackbar>
        )
    }
    return (
        <div className="test">
            {formData?.["submitted"] ?
                (
                    <div className="test__nav">
                        <div><h3>Test został wysłany. Dziękuję</h3></div>
                    </div>
                ) :
                (
                    <div>
                        {restError && renderErrorMsg()}
                        <div className="test__nav">
                            <div>
                                <h1>Placement test</h1>
                                <h5>Test można wypełnić oraz wysłać tylko raz. Powodzenia!</h5>
                            </div>
                            <div>
                                <LazyLoad once>
                                    <img src={logoImg} alt="English Effect Ewa Sobel logo" />
                                </LazyLoad>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit} validate="true" autoComplete="off">
                            <div className="test__question">
                                <TextField required id="standard-basic" label="Imię i nazwisko" name="name" onChange={handleOnChange} defaultValue={formData?.["name"]} />
                            </div>
                            {getFormControl("1", "The baby boy saw _______ in the mirror and started to cry.", ["itself", "himself", "herself", "themselves"])}
                            {getFormControl("2", "You ______________the restaurant. The food is terrible.", ["aren’t like ", "won’t like ", "don’t like ", "won’t liking"])}
                            {getFormControl("3", "It’s important _____________too much alcohol.", ["not to drink", "not drinking", "not drink", "don’t drink"])}
                            {getFormControl("4", "I can’t__________ to buy this house.", ["afford", "pay", "spend", "think"])}
                            {getFormControl("5", "You've got to get up very early tomorrow so don't forget to _______ your alarm.", ["set", "put", "wind", "fix"])}
                            {getFormControl("6", "Please don't forget to ring me when you _______ home.", ["will get", "get", "are going to get", "are getting"])}
                            {getFormControl("7", "I ________________ get in through the window.", ["managed to", "could", "was able", "manager"])}
                            {getFormControl("8", "Jack's parents _______ and so I am sure they would love to go to the exhibition.", ["like Picasso's paintings very much", "much Picasso's paintings like", "much like Picasso's paintings", "like very much Picasso's paintings"])}
                            {getFormControl("9", "Can you _________a favour?", ["do", "make me", "do me", "make"])}
                            {getFormControl("10", "It's a great place to live apart from the increasing volume of _______ that passes under my window every day.", ["traffic", "vehicles", "circulation", "transport"])}
                            {getFormControl("11", "The teacher asked if ___________ to bring our textbooks to class.", ["all we had remembered", "had we all remembered", "we had all remembered ", "had all we remembered"])}
                            {getFormControl("12", "Jake is very good at sport,____________ he’s not a big fan of watching it on TV.", ["whenever", "since", "despite ", "although"])}
                            {getFormControl("13", "The professor's unusual theories are sure to come under _______ from many of his rivals.", ["attack", "doubt", "criticism ", "objection"])}
                            {getFormControl("14", "I'll help you with your project _______ you promise to do the same for me some time.", ["as soon as", "as well as ", "as good as ", "as long as"])}
                            {getFormControl("15", "Pat has insisted _______ paying for the meal for all of us.", ["for", "to", "on", "in"])}
                            {getFormControl("16", "Our company is planning to _______ a new product early next year", ["campaign", "unfold", "embark", "launch"])}
                            {getFormControl("17", "Sophie has always thought the _______ of her uncle and so she is looking forward enormously to his visit.", ["heights", "world", "earth", "top"])}
                            {getFormControl("18", "I had no idea that you were planning to come — you _______ me.", ["might have warned", "could be warning", "would have warned", "should be warning"])}
                            {getFormControl("19", "Let's try to get home quickly before the rain really _______ .", ["sets in ", "sets on ", "sets off ", "sets down"])}
                            {getFormControl("20", "The quality of the young artist's paintings is beginning to gain _______ throughout theworld.", ["understanding", "familiarity", "recognition", "knowledge"])}
                            <div className="test__btn">
                                <Button variant="contained" color="primary" type="submit">
                                    wyślij test
                                </Button>
                            </div>
                        </form>
                    </div>
                )
            }
        </div >
    )
}