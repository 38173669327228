import React, { useState, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import './Regulamin.css'
import LazyLoad from 'react-lazyload';
import logoImg from '../Nav/Logo/logo.webp'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function Regulamin(props) {
    const [formData, setFormData] = useState(JSON.parse(localStorage.getItem(props.match.params.id)));
    const [restError, setRestError] = useState(false);

    useEffect(() => storeNewData("userId", props.match.params.id), []);

    function handleOnChange(e) {
        console.log(props);
        const { value, name } = e.target
        storeNewData(name, value);
    }

    function storeNewData(name, value) {
        const newData = { ...formData, [name]: value };
        setFormData(newData);
        localStorage.setItem(props.match.params.id, JSON.stringify(newData));
    }

    function getFormControl(taskNb, question, itemList) {
        return (
            <React.Fragment>
                <div className="test__question">
                    <FormControl component="fieldset" size='small'>
                        <FormLabel component="legend">
                            {taskNb + ". " + question}
                        </FormLabel>
                        <RadioGroup aria-label="gender" name={taskNb} onChange={handleOnChange}>
                            {itemList.map((e, index) => <FormControlLabel key={index} value={e} checked={formData?.[taskNb] === e} label={e} control={<Radio />} />)}
                        </RadioGroup>
                    </FormControl>
                </div>

            </React.Fragment>
        )
    }

    function handleSubmit(event) {
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            redirect: 'follow',
            body: JSON.stringify(formData)
        }

        fetch("/service-provider/english-effect/evaluateTest", requestOptions)
            .then((r) => {
                if (r.status !== 200) {
                    setRestError(true)
                } else {
                    storeNewData("submitted", true);
                }
            });
    }

    function renderErrorMsg() {
        return (
            <Snackbar autoHideDuration={6000} open={true} onClose={() => setRestError(false)}>
                <Alert onClose={() => setRestError(false)} severity="error">
                    Nie można wysłać testu, spróbuj później lub skontaktuj się z lektorem.
                </Alert>
            </Snackbar>
        )
    }
    return (
        <div className="regulamin-container">
          <h1>Regulamin zajęć z języka angielskiego</h1>
          <ol>
            <li>
              Lektor Ewa Sobel prowadząca działalność gospodarczą pod firmą English Effect Ewa Sobel, przy ul. Lwowskiej 11 w Ochojnie 32-40 oświadcza, iż posiada wiedzę i kompetencje potrzebne do prowadzenia zajęć z języka angielskiego.
            </li>
            <li>
              Zajęcia odbywają się od poniedziałku do piątku w dniach i godzinach indywidualnie ustalonych z Klientem.
            </li>
            <li>
              Dni i godziny zajęć mogą ulec zmianie przy porozumieniu obu stron.
            </li>
            <li>
              Płatności należy dokonać za miesiąc z góry, Klient zobowiązuje się uiścić opłatę za zadeklarowane zajęcia w terminie do 10-tego dnia miesiąca, lub do pierwszego dnia roboczego następującego bezpośrednio po nim, jeżeli 10-ty wypada w weekend lub inny dzień ustawowo wolny od pracy. Wyjątek stanowi miesiąc wrzesień, w którym płatności należy dokonać do 15-tego dnia miesiąca lub do pierwszego dnia roboczego następującego bezpośrednio po nim, jeżeli 15-ty wypada w weekend lub inny dzień ustawowo wolny od pracy.
            </li>
            <li>
              <strong>Opłata powinna być uiszczona:</strong>
              <ol type="a">
                <li>na rachunek bankowy Lektora: 26 1140 2004 0000 3102 5916 7259,</li>
                <li>gotówką do rąk własnych,</li>
                <li>blikiem na nr tel. 503489588</li>
              </ol>
              W pozostałych przypadkach płatność za zajęcia następuje bezpośrednio po przeprowadzonych zajęciach.
            </li>
            <li>
              W razie niezrealizowania zajęć w ustalonym terminie z winy Lektora, zobowiązuje się on do odrobienia tych zajęć w najbliższym możliwym terminie, uwzględniając preferencje godzinowe Klienta, chyba że w tym czasie będą się odbywały inne zajęcia.
              <ol type="a">
                <li>
                  Jeżeli w przypadku opisanym w ust. 6 nie uda się ustalić wspólnie z Klientem dogodnego terminu do odrobienia zajęć, Lektor zobowiązuje się zwrócić Klientowi opłatę za zajęcia, które nie odbyły się z winy Lektora.
                </li>
              </ol>
            </li>
            <li>
              W celu zapewnienia efektywności prowadzonych zajęć Klient zobowiązuje się do poinformowania Lektora o nieobecności telefonicznie, przez SMS lub Messenger najpóźniej do godziny 19:00 poprzedniego dnia.
              <ol type="a">
                <li>
                  W danym miesiącu Klient może odwołać 1. zajęcia w przypadku zajęć 1x w tygodniu oraz 2 zajęć w przypadku zajęć 2x w tygodniu. Opłata za terminowo odwołane zajęcia zostanie zwrócona Klientowi lub odliczona od opłaty za zajęcia w kolejnym miesiącu.
                </li>
                <li>
                  W przypadku większej ilości odwołanych zajęć w danym miesiącu zgodnie z ust. 6 zajęcia zostaną odrobione w innym najbliższym terminie ustalonym wspólnie z Klientem bądź w czerwcu.
                </li>
                <li>
                  W razie niepoinformowania Lektora o zamiarze odwołania zajęć w ciągu w/w godzin przed ich planowym odbyciem, zajęcia traktowane są jako zrealizowane, a Klient będzie musiał wnieść za nie opłatę w pełnej kwocie.
                </li>
                <li>
                  Wyjątkiem od powyższych ustaleń jest nagła niedyspozycja, o której Klient nie mógł wiedzieć odpowiednio wcześniej (wypadek, itp.) lub działanie siły wyższej.
                </li>
              </ol>
            </li>
            <li>
              Klient może zrezygnować z zajęć w każdym miesiącu z zastrzeżeniem miesięcznego okresu wypowiedzenia.
            </li>
          </ol>
        </div>
      );
    };
