import React from 'react'
import './Cennik.css'

export default function Cennik() {
    return (
        <div className="cennik" id="cennik">
            <div className="cennik__main-item">
                <p><span className="cennik__text">Cennik</span></p>
                <p>
                    <span className="cennik__text">
                        CENA OBEJMUJE 60 MIN ZAJĘĆ.
                        MOŻLIWE ZAJĘCIA PRÓBNE DO 30 MIN <b>DARMOWE</b>.
                    </span>
                </p>
                <div className="cennik__container">
                    <div className="cennik__item">
                        <p className="cennik_cena">
                            80 zł
                        </p>
                        <p>1 osoba</p>
                    </div>
                    <div className="cennik__item">
                        <p className="cennik_cena">
                            50 zł/os.
                        </p>
                        <p>
                            w parach
                        </p>
                    </div>
                    <div className="cennik__item">
                        <p className="cennik_cena">
                            40 zł/os.
                        </p>
                        <p>
                            3 osoby
                        </p>
                    </div>
                    <div className="cennik__item">
                        <p className="cennik_cena">
                            35zł/os.
                        </p>
                        <p>
                            4 osoby
                        </p>
                    </div>
                </div>
                <div className="cennik__female">
                    <div className="cennik__female-item"></div>
                </div>
            </div>
        </div>
    )
}