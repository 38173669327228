import React from 'react'
import './FrontSection.css'
import frontImg from './front.webp';
import LazyLoad from 'react-lazyload';
import zoomImg from './zoom.webp'
import skypeImg from './skype.webp'
export default function FrontSection() {
    return (
        <section className="front">
            <div>
                <h1 className="front__text-company-name">
                    ENGLISH EFFECT
                </h1>
                <h1 className="front__text-ewa">Ewa Sobel</h1>
                <p className="front-text-haslo-1">Zajęcia skrojone do Twoich potrzeb</p>
                <div className="front__online-section">
                    <h2 className="front-text-haslo-1">online </h2>
                    <div className="front__communicators">
                        <LazyLoad once >
                            <img className="front__zoom" src={zoomImg} alt="Ewa Sobel English Effect" />
                        </LazyLoad>
                        <LazyLoad once >
                            <img className="front__skype" src={skypeImg} alt="Ewa Sobel English Effect" />
                        </LazyLoad>
                    </div>
                </div>
                <p className="front-text-haslo-1">lub stacjonarnie</p>
            </div>
            <LazyLoad once >
                <img className="front__img" src={frontImg} alt="Ewa Sobel English Effect" />
            </LazyLoad>
        </section>
    )
}