import React from 'react';
import Logo from './Logo';
import Menu from './Menu';
import './Nav.css';

export default function Nav() {
    return (
        <header className="nav">
            <Logo />
            <Menu />
        </header>
    )
}