import './App.css';
import Nav from './components/Nav'
import FrontSection from './components/content/FrontSection'
import Oferta from './components/content/Oferta'
import FormyZajec from './components/content/FormyZajec'
import Olektorze from './components/content/Olektorze'
import Cennik from './components/content/Cennik'
import Wyroznia from './components/content/Wyroznia'
import Kontakt from './components/content/Kontakt'
import Test from './components/Test'
import Regulamin from './components/Regulamin'
import './fonts/font.css'
import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <Route path="/test/:id" component={Test}/>
          <Route path="/regulamin" component={Regulamin}/>
          <Route exact path='/'>
            <Nav />
            <FrontSection />
            <Oferta />
            <FormyZajec />
            <Olektorze />
            <Cennik />
            <Wyroznia />
            <Kontakt />
          </Route>
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
